"use client";

import { FC, PropsWithChildren } from "react";
import { PostHogProvider as PostHogOriginal } from "posthog-js/react";
import posthog, { PostHogConfig } from "posthog-js";

const OPTIONS: Partial<PostHogConfig> = {
  api_host: "https://eu.posthog.com",
  loaded: (instance) => {
    if (process.env.NODE_ENV === "development") instance.debug();
  },
};

const POSTHOG_API_KEY = "phc_WTAK77JGnnZlglOdEb4uBQgbfYpA6iIvMJPig5PLXcw";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(POSTHOG_API_KEY, OPTIONS);
  // @ts-ignore
  window.posthog = posthog;
}

export const PostHogProvider: FC<PropsWithChildren> = ({ children }) => (
  <PostHogOriginal
    client={posthog}
  >
    { children }
  </PostHogOriginal>
);
